:root
  --primary-color: #CAC5A9
  --color-primary: #CAC5A9
  --primary-r: 202
  --primary-g: 197
  --primary-b: 169
  --primary-h: 51
  --primary-s: 23.7%
  --primary-l: 72.7%
  --switch: 100%
:root
  --secondary-color: #6C8BB8
  --color-secondary: #6C8BB8
